import React from 'react'
import "./LauderMain.css";
import logo from './img/bilka.png';

const LauderMain = () => {
  return (
    <div className="loader-top">
      <div class="loader-container">
        <div class="flipping-cards">
        <div class="card-loader">  <img className="logo-leader" src={logo} /></div>
          <div class="card-loader">К</div>
          <div class="card-loader">О</div>
          <div class="card-loader">Ц</div>
          <div class="card-loader">Ю</div>
          <div class="card-loader">Б</div>
          <div class="card-loader">І</div>
          <div class="card-loader">Л</div>
          <div class="card-loader">К</div>
          <div class="card-loader">А</div>
        </div>
      </div>
    </div>
  );
}

export default LauderMain